import React, { useEffect, useState, useContext, useRef } from "react";
import { navBarContext, authContext } from "./context/context";
import {
  updateLatestActions,
  addToSummary,
  pitchTracking,
  videoPitchTracking,
  urlTracking,
  useWindowDimensions
} from "../utils";
import {
  StartupButton,
  Modal,
  TextButton,
  PrairiButton,
  MatchesReminder,
  Loading,
  StartupProfile,
  CompleteYourProfile,
  Badge,
  FlagLink,
  Viewer,
  FloatingButtons,
} from "@peterfosso/prairi-components";
import Logo from "../assets/prairiwhite.png";
import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router-dom";
import Flag from "../assets/flag.png";
import ThankYou from "../assets/thankyou-unicorn.png";
import {
  collection,
  db,
  getDoc,
  doc,
  query,
  where,
  getDocs,
  updateDoc,
  Timestamp,
  addDoc,
} from "../firebase/firebaseApp";
import axios from "axios";
import Unicorn from "../assets/silver-unicorn.png";
import { usStates } from "../countries";
import NewMatch from "./NewMatch";

const Dashboard1 = () => {
  const { navBarHeight } = useContext(navBarContext);
  const { currentUser } = useContext(authContext);
  const { width, height } = useWindowDimensions();
  const deviceWidth = parseFloat(width);
  const deviceHeight = parseFloat(height);
  const [interestedStyle, setInterestedStyle] = useState({
    borderColor: "#92d050",
    color: "white",
  });
  const [archivedStyle, setArchivedStyle] = useState({
    borderColor: "#a5a5a5",
    color: "#a5a5a5",
  });
  const [interestedStartups, setInterestedStartups] = useState([]);
  const [archivedStartups, setArchivedStartups] = useState([]);
  const [pendingStartups, setPendingStartups] = useState([]);
  const [interestedStartupsData, setInterestedStartupsData] = useState([]);
  const [archivedStartupsData, setArchivedStartupsData] = useState([]);
  const [pendingStartupsData, setPendingStartupsData] = useState([]);
  const [dataFetched, setDataFetched] = useState(false);
  const [showInterested, setShowInterested] = useState(true);
  const [showArchived, setShowArchived] = useState(false);
  const [currentUserData, setCurrentUserData] = useState({});
  const [currentUserId, setCurrentUserId] = useState("");
  const [notInterestedReasons, setNotInterestedReasons] = useState("");
  const [interestedPopUp, setInterestedPopUp] = useState(false);
  const [notInterestedPopUp, setNotInterestedPopUp] = useState(false);
  const [userRef, setUserRef] = useState({});
  const [shownStartup, setShownStartup] = useState(false);
  const [shownDeck, setShownDeck] = useState(false);
  const [shownArchivedStartup, setShownArchivedStartup] = useState(false);
  const [shownArchivedDeck, setShownArchivedDeck] = useState(false);
  const [newMatchesOpen, setNewMatchesOpen] = useState(true);
  const [archivedPopUp, setArchivedPopUp] = useState(false);
  const [interestedViewerSrc, setInterestedViewerSrc] = useState(false);
  const [archivedViewerSrc, setArchivedViewerSrc] = useState(false);
  const [notInterestedPopUpFromArchived, setNotInterestedPopUpFromArchived] =
    useState(false);
  const [textReason, setTextReason] = useState("");
  const [flagReason, setFlagReason] = useState(null);
  const [thankYouPopUp, setThankYouPopUp] = useState(false);
  const [flagFromInterestedPopUp, setFlagFromInterestedPopUp] = useState(false);
  const [flagFromArchivedPopUp, setFlagFromArchivedPopUp] = useState(false);
  const [potentialName, setPotentialName] = useState("");
  const [potentialEmail, setPotentialEmail] = useState("");
  const [potentialPhone, setPotentialPhone] = useState("");
  const [potentialCode, setPotentialCode] = useState("");
  const [potentialId, setPotentialId] = useState("");
  const [shareError, setShareError] = useState("");
  const [tySharePopup, setTySharePopup] = useState(false);
  const [isFirstInMobile, setIsFirstInMobile] = useState(false);
  const time = Timestamp.fromDate(new Date())
  const date = time
    .toDate()
    .toLocaleDateString("en-US", { month: "2-digit", day: "2-digit", year: "2-digit" });
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const isLarge = useMediaQuery({ minWidth: 1824 });
  const containerRef = useRef(null);
  let navigate = useNavigate();
  // const [welcomePopup, setWelcomePopup] = useState(true);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (potentialPhone !== "" || potentialEmail !== "") {
      setShareError(false);
    }
  }, [potentialEmail, potentialPhone]);

  const getDataFromUser = async (uid) => {
    //pass currentUser.uid when the moment comes
    try {
      const q = query(collection(db, "users"), where("uid", "==", uid));
      const querySnapshot = await getDocs(q);
      let userData;
      let userId;
      querySnapshot.forEach((doc) => {
        userData = doc.data();
        userId = doc.id;
      });
      // console.log("userData", JSON.stringify(userData))
      setCurrentUserData(userData);
      setCurrentUserId(userId);
      let ref = doc(db, "users", userId);
      setUserRef(ref);
      if ((!userData.firstInMobile || userData.firstInMobile===date) && isMobile) {
        setIsFirstInMobile(true);
        await updateDoc(ref, {firstInMobile: date})

      }

      // console.log("currentUserData inside getDataFromUser", currentUserData)
    } catch (error) {
      //console.log(error);
    }
  };

  const handleCloseProfileAndDeck = () => {
    setShownDeck(false);
    setShownStartup(false);
    setShownArchivedStartup(false);
    setShownArchivedDeck(false);
  };

  const handleShownDeck = (startup) => {
    if (showInterested) {
      setShownDeck(startup.formData.company.deck);
    } else {
      setShownArchivedDeck(startup.formData.company.deck);
    }
  };

  const handleDesktopClick = (startup) => {
    if (showInterested) {
      setShownStartup(startup);
      setInterestedViewerSrc(startup.formData.company.deck);
    } else {
      setShownArchivedStartup(startup);
      setArchivedViewerSrc(startup.formData.company.deck);
    }
  };

  const handlePitchTracking = (startupId, investorId, isMobile, viewerSrc) => {
    //if (isMobile) {
    window.open(viewerSrc, "_blank");
    // }
    // pitchTracking(startupId, investorId, isMobile);
    // if (showInterested) {
    //   setInterestedViewerSrc(viewerSrc);
    // }
    // if (showArchived) {
    //   setArchivedViewerSrc(viewerSrc);
    // }
  };

  const handleVideoPitchTracking = (
    startupId,
    investorId,
    isMobile,
    viewerSrc
  ) => {
    videoPitchTracking(startupId, investorId, isMobile);
    //if (!viewerSrc.includes("youtube") || isMobile) {
    const absoluteVideoPitch = viewerSrc
      ? /^https?:\/\//.test(viewerSrc)
        ? viewerSrc
        : `http://${viewerSrc}`
      : "";
    window.open(absoluteVideoPitch, "_blank");
    // return;
    // }
    // if (showInterested) {
    //   setInterestedViewerSrc(viewerSrc);
    // }
    // if (showArchived) {
    //   setArchivedViewerSrc(viewerSrc);
    // }
  };

  const handleNotInterestedReason = (event) => {
    const selectedValue = event.target.value;
    if (event.target.checked) {
      if (selectedValue !== "other") {
        setNotInterestedReasons([...notInterestedReasons, selectedValue]);
      }
    } else {
      setNotInterestedReasons(
        notInterestedReasons.filter((value) => value !== selectedValue)
      );
    }
  };

  const handleFlagReason = (event) => {
    const selectedValue = event.target.value;
    setFlagReason(selectedValue);
  };

  const handleNotInterestedReasonText = (event) => {
    const checkbox = document.getElementById("other");
    const checkbox2 = document.getElementById("other-reason");
    checkbox.checked = true;
    checkbox2.checked = true;
    setTextReason(event.target.value);
  };

  const openInterestedCloseArchived = (id) => {
    setArchivedPopUp(false);
    setInterestedPopUp(id);
  };

  const openNotInterestedCloseArchived = (id) => {
    setArchivedPopUp(false);
    setNotInterestedPopUpFromArchived(id);
  };

  const fetchInterestedById = async () => {
    try {
      if (currentUserData && currentUserData.interestedMatches) {
        const interestedIds = currentUserData.interestedMatches;

        // Use Promise.all to fetch all documents concurrently
        const fetchPromises = interestedIds.map(async (userId) => {
          const docRef = doc(db, "users", userId);
          const docSnap = await getDoc(docRef);
          return docSnap.data();
        });

        // Wait for all fetchPromises to complete
        const userDatas = await Promise.all(fetchPromises);

        // Now userDatas contains the data for each user
        const matchingUsers = userDatas.map((userData, index) => ({
          ...userData,
          id: interestedIds[index], // assuming interestedIds is an array of user IDs
        }));

        //console.log("Users with matching IDs:", matchingUsers);
        //console.log("interestedStartupsData after set in fetchUsersById", JSON.stringify(interestedStartupsData));
        setInterestedStartupsData(matchingUsers.reverse());
      }
    } catch (error) {
      console.error("Error fetching users by ID:", error);
    }
  };

  const fetchArchivedById = async () => {
    try {
      if (currentUserData && currentUserData.archivedMatches) {
        const archivedIds = currentUserData.archivedMatches;

        // Use Promise.all to fetch all documents concurrently
        const fetchPromises = archivedIds.map(async (userId) => {
          const docRef = doc(db, "users", userId);
          const docSnap = await getDoc(docRef);
          return docSnap.data();
        });

        // Wait for all fetchPromises to complete
        const userDatas = await Promise.all(fetchPromises);

        // Now userDatas contains the data for each user
        const matchingUsers = userDatas.map((userData, index) => ({
          ...userData,
          id: archivedIds[index], // assuming interestedIds is an array of user IDs
        }));

        //("Users with matching IDs:", matchingUsers);
        //console.log("archivedStartupsData after set in fetchUsersById", JSON.stringify(archivedStartupsData));
        setArchivedStartupsData(matchingUsers.reverse());
      }
    } catch (error) {
      console.error("Error fetching users by ID:", error);
    }
  };

  const fetchPendingById = async () => {
    try {
      if (currentUserData && currentUserData.pendingMatches) {
        const pendingIds = currentUserData.pendingMatches;

        const fetchPromises = pendingIds.map(async (userId) => {
          const docRef = doc(db, "users", userId);
          const docSnap = await getDoc(docRef);
          return docSnap.data();
        });
        const userDatas = await Promise.all(fetchPromises);

        const matchingUsers = userDatas.map((userData, index) => ({
          ...userData,
          id: pendingIds[index],
        }));
        setPendingStartupsData(matchingUsers.reverse());
      }
    } catch (error) {
      console.error("Error fetching users by ID:", error);
    }
  };

  const fetchData = async () => {
    try {
      if (currentUser) {
        // Assuming currentUser is defined in your component
        await getDataFromUser(currentUser.uid); // Pass the UID from currentUser
        // console.log("interestedStartupData inside fetchData", interestedStartupsData)
        // console.log("archivedStartupData inside fetchData", archivedStartupsData)
        setDataFetched(true);
      }
    } catch (error) {
      //console.log('error fetching data');
    }
  };

  useEffect(() => {
    fetchData();
    if (!currentUser) {
      navigate("/login");
    }
  }, [currentUser]);

  useEffect(() => {
    if (currentUserData.category === "startup") {
      navigate("/startupdashboard");
    }
    else if (currentUserData.category === "investor") {
      fetchInterestedById();
      fetchArchivedById();
      fetchPendingById();
      if (
        !currentUserData.agreed &&
        currentUserData.country !== "United States"
      ) {
        //(JSON.stringify(currentUserData))
        navigate("/investorwelcome");
      }

      //console.log(JSON.stringify(currentUserData))
    }

  }, [currentUserData]);

  const seeNotifications = async () => {
    //console.log("see notif")
    await updateLatestActions(
      userRef,
      "Clicked Matches notification banner",
      isMobile
    );
    navigate(`/notifications/${currentUserId}`);
  };

  useEffect(() => {
    // When currentUserData changes, update the state variables
    if (currentUserData) {
      if (currentUserData.interestedMatches) {
        setInterestedStartups(currentUserData.interestedMatches);
        // console.log("interested inside useEffect", currentUserData.interestedMatches)
      }
      if (currentUserData.archivedMatches) {
        setArchivedStartups(currentUserData.archivedMatches);
        // console.log("archived inside useEffect", currentUserData.archivedMatches)
      }
      if (currentUserData.pendingMatches) {
        setPendingStartups(currentUserData.pendingMatches);
      }
      // console.log("interested data", interestedStartupsData)
    }
  }, [currentUserData, currentUserId]);

  const [AdminOnline, setAdminOnline] = useState(false);
  const handleAdmin = () => {
    setAdminOnline(true);
    navigate("/admindashboard");
  };

  const removeInterestedMatch = async (userId, matchId) => {
    try {
      // Fetch the current user's document
      const userRef = doc(db, "users", userId);
      const userDoc = await getDoc(userRef);

      if (userDoc.exists()) {
        //
        // Get the current "pendingMatches" array
        const interestedMatches = userDoc.data().interestedMatches;

        // Find and remove the match from the array
        const updatedMatches = interestedMatches.filter(
          (match) => match !== matchId
        );
        // Update the user's document with the modified "pendingMatches" array
        await updateDoc(userRef, { interestedMatches: updatedMatches });
        setInterestedStartups(updatedMatches);

        //console.log(`Match ${matchId} removed from pendingMatches`);
      } else {
        //  console.log("User document not found");
      }
    } catch (error) {
      // console.error("Error removing match:", error);
    }
  };

  const removeArchivedMatch = async (userId, matchId) => {
    try {
      // Fetch the current user's document
      const userRef = doc(db, "users", userId);
      const userDoc = await getDoc(userRef);
      // console.log("userId and matchId inside removeArchived", userId, matchId)
      if (userDoc.exists()) {
        //
        // Get the current "pendingMatches" array
        const archivedMatches = userDoc.data().archivedMatches;

        // Find and remove the match from the array
        const updatedMatches = archivedMatches.filter(
          (match) => match !== matchId
        );
        // Update the user's document with the modified "pendingMatches" array
        await updateDoc(userRef, { archivedMatches: updatedMatches });
        setArchivedStartups(updatedMatches);

        //console.log(`Match ${matchId} removed from pendingMatches`);
      } else {
        //  console.log("User document not found");
      }
    } catch (error) {
      // console.error("Error removing match:", error);
    }
  };

  const addInterestedMatch = async (userId, matchId) => {
    try {
      const userRef = doc(db, "users", userId);
      const userDoc = await getDoc(userRef);
      if (userDoc) {
        let updatedMatches;
        const userInterestedMatches = userDoc.data().interestedMatches || [];
        const startupToAdd = matchId;
        if (!userInterestedMatches.includes(matchId)) {
          updatedMatches = [...userInterestedMatches, startupToAdd];
        }
        await updateDoc(userRef, { interestedMatches: updatedMatches });

        // console.log(`Match ${matchId} added to interested`);
      }
    } catch (error) {
      //console.error("Error removing match:", error);
    }
  }; //

  const addNotInterestedMatch = async (userId, matchId) => {
    try {
      const userRef = doc(db, "users", userId);
      const userDoc = await getDoc(userRef);
      const startupRef = doc(db, "users", matchId);
      const startupDoc = await getDoc(startupRef);
      console.log("Entered addNotInterestedMatch");
      if (userDoc) {
        let updatedMatches;
        let updatedStartupNot;
        let updatedInvestorInterests; //technically belong to startup
        let updatedInvestorContacts; //technically belong to startup
        let updatedArchivedStartup;
        //gotta delete the investor from startup document
        const userNotInterestedMatches =
          userDoc.data().notInterestedMatches || [];
        const startupNotInterestedMatches =
          startupDoc.data().notInterestedMatches || [];
        const startupInvestorInterests =
          startupDoc.data().investorInterests || [];
        const startupInvestorContacts =
          startupDoc.data().investorContacts || [];
        const startupArchivedMatches = startupDoc.data().archivedMatches || [];
        console.log(startupInvestorInterests);
        const startupToAdd = matchId;
        const investorToAdd = userId;
        if (startupInvestorInterests.includes(userId)) {
          updatedInvestorInterests = startupInvestorInterests.filter(
            (match) => match !== userId
          );
          console.log(`updated: ${updatedInvestorInterests}`);
        }
        if (startupInvestorContacts.includes(userId)) {
          updatedInvestorContacts = startupInvestorContacts.filter(
            (match) => match !== userId
          );
        }
        if (startupArchivedMatches.includes(userId)) {
          updatedArchivedStartup = startupArchivedMatches.filter(
            (match) => match !== userId
          );
        }
        if (!userNotInterestedMatches.includes(matchId)) {
          updatedMatches = [...userNotInterestedMatches, startupToAdd];
        }
        if (!startupNotInterestedMatches.includes(userId)) {
          updatedStartupNot = [...startupNotInterestedMatches, investorToAdd];
        }
        await updateDoc(userRef, { notInterestedMatches: updatedMatches });
        await updateDoc(startupRef, {
          notInterestedMatches: updatedStartupNot,
          investorInterests: updatedInvestorInterests,
          investorContacts: updatedInvestorContacts,
          archivedMatches: updatedArchivedStartup,
        });
        //console.log(`Match ${matchId} added to not interested`);
      }
    } catch (error) {
      // console.error("Error not interested match:", error);
    }
  };

  const storeInterestedInStartup = async (docId, matchId) => {
    try {
      const userDocRef = doc(db, "users", docId);
      const userDoc = await getDoc(userDocRef);

      const startupDocRef = doc(db, "users", matchId);
      const startupDoc = await getDoc(startupDocRef);

      if (startupDoc.exists()) {
        let updatedInvestorInterests;
        const startupInvestorInterests =
          startupDoc.data().investorInterests || [];
        const investorInterestToAdd = userDoc.id;
        updatedInvestorInterests = [
          ...startupInvestorInterests,
          investorInterestToAdd,
        ];

        await updateDoc(startupDocRef, {
          investorInterests: updatedInvestorInterests,
        });
        // console.log(`Match ${matchId} received investor`);
      } else {
        // console.log("No such document with matchId:", matchId);
      }
    } catch (error) {
      console.error("Error storing investor interest", error);
    }
  };

  //
  const handleInterestedEmailNotification = async (docId, matchId) => {
    try {
      const startupDocRef = doc(db, "users", matchId);
      const startupDoc = await getDoc(startupDocRef);

      if (startupDoc.exists() && startupDoc.data().emailConsent) {
        // Send an email to the startup about a new investor match
        const payload = {
          sender: {
            name: "PRAIRI",
            email: "info@prairi.com",
          },
          to: [
            {
              name: startupDoc.data().contactName,
              email: startupDoc.data().email,
            },
          ],
          subject:
            "Congratulations! PRAIRI has found a new Investor match for you.",
          templateId: 33,
          params: {
            DOCID: startupDoc.id,
            EMAIL: startupDoc.data().email,
            INVID: docId,
            NAME: startupDoc.data().contactName,
          },
        };

        const { data } = await axios({
          method: "POST",
          url: "https://api.sendinblue.com/v3/smtp/email",
          headers: {
            Accept: "application/json",
            "api-key": process.env.REACT_APP_SENDINBLUE_API_KEY,
            "Content-Type": "application/json",
          },
          data: JSON.stringify(payload),
        });

        // console.log(data);
        // setEmailSent(true);
      }
    } catch (err) {
      console.error("Error sending new match email to startup", err);
    }
  };

  const handleInterestedSMSNotification = async (matchId) => {
    try {
      const startupDocRef = doc(db, "users", matchId);
      const startupDoc = await getDoc(startupDocRef);

      if (startupDoc.exists() && startupDoc.data().smsConsent) {
        const messagesCollection = collection(db, "messages");
        const dataToStore = {
          to: `+${startupDoc.data().phone}`,
          body: "Congratulations! PRAIRI has found a new Investor who is interested in your startup! Check them out on your dashboard: https://prairi.com/dashboard",
        };
        await addDoc(messagesCollection, dataToStore);
      }
    } catch (error) {
      console.error("Error sending SMS notification to startup", error);
    }
  };

  const storeFeedback = async (matchId, userId) => {
    try {
      let feedbackToAdd = notInterestedReasons;
      const userRef = doc(db, "users", userId);
      const userDoc = await getDoc(userRef);
      let feedbackCount = userDoc.data().feedbacksGiven || 0;
      if (document.getElementById("other").checked && textReason) {
        feedbackToAdd.push(textReason);
      }

      const startupDocRef = doc(db, "users", matchId);
      const startupDoc = await getDoc(startupDocRef);

      if (startupDoc.exists()) {
        const currentDate = new Date().toISOString();
        const data = {
          [currentDate]: feedbackToAdd,
        };

        let updatedFeedbacks;
        const startupInvestorFeedbacks =
          startupDoc.data().investorFeedback || [];
        updatedFeedbacks = [...startupInvestorFeedbacks, data];

        await updateDoc(startupDocRef, { investorFeedback: updatedFeedbacks });
        await updateDoc(userRef, { feedbacksGiven: feedbackCount + 1 });
        // console.log("Feedback stored for startup");
      } else {
        // console.log("No such document with matchId:", matchId);
      }
    } catch (error) {
      console.error("Error storing feedback", error);
    }
  };

  const storeFlag = async (matchId, reason) => {
    try {
      const startupDocRef = doc(db, "users", matchId);
      const startupDoc = await getDoc(startupDocRef);
      const userRef = doc(db, "users", currentUserId);
      if (startupDoc.exists()) {
        let updatedFlags;
        const startupFlags = startupDoc.data().flags || [];
        updatedFlags = [...startupFlags, reason];

        await updateDoc(startupDocRef, { flags: updatedFlags });
        updateLatestActions(
          userRef,
          "Flagged a startup from dashboard",
          isMobile
        );
        // console.log("Flag stored for startup");
      } else {
        // console.log("No such document with matchId:", matchId);
      }
    } catch (error) {
      console.error("Error storing flag", error);
    }
  };

  const handleFeedbackEmailNotification = async (matchId) => {
    try {
      const startupDocRef = doc(db, "users", matchId);
      const startupDoc = await getDoc(startupDocRef);

      if (startupDoc.exists() && startupDoc.data().emailConsent) {
        // Send an email to the startup about feedback
        const payload = {
          sender: {
            name: "PRAIRI",
            email: "info@prairi.com",
          },
          to: [
            {
              name: startupDoc.data().contactName,
              email: startupDoc.data().email,
            },
          ],
          subject: "We matched you with an investor...",
          templateId: 34,
          params: {
            EMAIL: startupDoc.data().email,
          },
        };

        const { data } = await axios({
          method: "POST",
          url: "https://api.sendinblue.com/v3/smtp/email",
          headers: {
            Accept: "application/json",
            "api-key": process.env.REACT_APP_SENDINBLUE_API_KEY,
            "Content-Type": "application/json",
          },
          data: JSON.stringify(payload),
        });

        // console.log(data);
        const lastNotification = new Date().toISOString();
        await updateDoc(startupDocRef, { lastNotification: lastNotification });
        //setEmailSent(true);
      }
    } catch (error) {
      console.error("Error sending feedback email to startup", error);
    }
  };

  const handleFeedbackSMSNotification = async (matchId) => {
    try {
      const startupDocRef = doc(db, "users", matchId);
      const startupDoc = await getDoc(startupDocRef);

      if (startupDoc.exists() && startupDoc.data().smsConsent) {
        const messagesCollection = collection(db, "messages");
        const dataToStore = {
          to: `+${startupDoc.data().phone}`,
          body: "PRAIRI matched you with an investor, but they were not interested. However, they left some feedback for you. Check it out on your dashboard: https://prairi.com/dashboard",
        };

        await addDoc(messagesCollection, dataToStore);
      }
    } catch (error) {
      console.error("Error sending feedback SMS to startup", error);
    }
  };

  const handleInterestedClick = () => {
    setInterestedStyle({ borderColor: "#92d050", color: "white" });
    setArchivedStyle({ borderColor: "#a5a5a5", color: "#a5a5a5" });
    setShowInterested(true);
    setShowArchived(false);
    setShownDeck(false);
    setShownStartup(false);
    setShownArchivedStartup(false);
    setShownArchivedDeck(false);
  };

  const handleArchivedClick = () => {
    setInterestedStyle({ borderColor: "#a5a5a5", color: "#a5a5a5" });
    setArchivedStyle({ borderColor: "#ffc000", color: "white" });
    setShowArchived(true);
    setShowInterested(false);
    setShownDeck(false);
    setShownStartup(false);
    setShownArchivedStartup(false);
    setShownArchivedDeck(false);
  };

  const handleInterest = async (matchId) => {
    try {
      //console.log("starting interest function")
      const startupRef = doc(db, "users", matchId);
      const startupDoc = await getDoc(startupRef);
      storeInterestedInStartup(currentUserId, matchId);
      handleInterestedEmailNotification(currentUserId, matchId);
      handleInterestedSMSNotification(currentUserId, matchId);
      removeArchivedMatch(currentUserId, matchId);
      addInterestedMatch(currentUserId, matchId);
      handleInterestedEmailNotification(currentUserId, matchId);
      const updatedArchivedData = archivedStartupsData.filter(
        (item) => item.id !== matchId
      );
      setArchivedStartupsData(updatedArchivedData);
      updateLatestActions(
        userRef,
        `Selected Interested in ${
          startupDoc.data().formData.company.company
        } (dashboard)`,
        isMobile
      );
      addToSummary(matchId, `Selected 'Interested'`, currentUserId);
      setInterestedPopUp(false);
      fetchData();
      fetchInterestedById();
      //console.log("handle interested")
      // console.log("doc id", docId)
    } catch (error) {
      // Handle the error
      // console.log("Error updating document:", error);
    }
  };

  const handleNotInterestFromInterestedFeedback = async (
    docId,
    matchId,
    feedback
  ) => {
    try {
      // handleInterestedEmailNotification(docId, matchId)
      if (notInterestedReasons.length === 0) {
        handleNotInterestFromInterestedNoFeedback(docId, matchId);
        return;
      }
      storeFeedback(matchId, docId);
      const currentDate = new Date().toISOString();
      const usersCollection = collection(db, "users");

      // Fetch the user document by matching on the "id" field
      const startupDocRef = doc(usersCollection, matchId);
      const startupDocSnap = await getDoc(startupDocRef);

      if (startupDocSnap.exists()) {
        // Access the user data using startupDocSnap.data()
        const startupData = startupDocSnap.data();

        const lastNotificationDate =
          (startupData.lastNotification || "").split("T")[0] ?? "";

        if (lastNotificationDate !== currentDate.split("T")[0]) {
          handleFeedbackEmailNotification(matchId);
          handleFeedbackSMSNotification(matchId);
        }

        removeInterestedMatch(docId, matchId);
        addNotInterestedMatch(docId, matchId);

        const updatedInterestedData = interestedStartupsData.filter(
          (item) => item.id !== matchId
        );
        setInterestedStartupsData(updatedInterestedData);
        updateLatestActions(
          userRef,
          `Selected Not Interested in ${startupData.formData.company.company} and provided feedback (dashboard)`,
          isMobile
        );
        addToSummary(
          matchId,
          `Selected 'Not Interested' and provided feedback`,
          currentUserId
        );
        setNotInterestedPopUp(false);
      } else {
        console.log("User document not found");
      }
    } catch (error) {
      // Handle the error
      console.error("Error handling not interested from feedback:", error);
    }
  };

  const handleFlaggingFromInterestedFeedback = async (
    docId,
    matchId,
    reason
  ) => {
    try {
      storeFlag(matchId, reason);
      const usersCollection = collection(db, "users");

      // Fetch the user document by matching on the "id" field
      const startupDocRef = doc(usersCollection, matchId);
      const startupDocSnap = await getDoc(startupDocRef);

      if (startupDocSnap.exists()) {
        // Access the user data using startupDocSnap.data()
        const startupData = startupDocSnap.data();

        removeInterestedMatch(docId, matchId);
        addNotInterestedMatch(docId, matchId);

        const updatedInterestedData = interestedStartupsData.filter(
          (item) => item.id !== matchId
        );
        setInterestedStartupsData(updatedInterestedData);
        setFlagFromInterestedPopUp(false);
        setThankYouPopUp(true);

        const payload = {
          sender: {
            name: "PRAIRI",
            email: "invite@prairi.com",
          },
          to: [
            {
              name: "Carolina",
              email: "kro.ruz@gmail.com",
            },
            {
              name: "Peter",
              email: "peter@prairi.com",
            },
            //   {name:"hoorieh",
            // email: "hoorieh.23@gmail.com"},
          ],
          subject: "Flagged user",
          textContent: "This user has been flagged",
          templateId: 35,
          params: {
            INVESTOR: docId,
            STARTUP: matchId,
            REASON: reason,
          },
        };

        const { data } = await axios({
          method: "POST",
          url: "https://api.sendinblue.com/v3/smtp/email",
          headers: {
            Accept: "application/json",
            "api-key": process.env.REACT_APP_SENDINBLUE_API_KEY,
            "Content-Type": "application/json",
          },
          data: JSON.stringify(payload),
        });
      } else {
        console.log("User document not found");
      }
    } catch (error) {
      // Handle the error
      console.error("Error handling flagging from interested feedback:", error);
    }
  };

  const handleNotInterestFromArchivedFeedback = async (
    docId,
    matchId,
    feedback
  ) => {
    try {
      // handleInterestedEmailNotification(docId, matchId)
      if (notInterestedReasons.length === 0) {
        handleNotInterestFromArchivedNoFeedback(docId, matchId);
        return;
      }
      storeFeedback(matchId, docId);
      const currentDate = new Date().toISOString();
      const usersCollection = collection(db, "users");

      // Fetch the user document by matching on the "id" field
      const startupDocRef = doc(usersCollection, matchId);
      const startupDocSnap = await getDoc(startupDocRef);

      if (startupDocSnap.exists()) {
        // Access the user data using startupDocSnap.data()
        const startupData = startupDocSnap.data();

        const lastNotificationDate =
          (startupData.lastNotification || "").split("T")[0] ?? "";

        if (lastNotificationDate !== currentDate.split("T")[0]) {
          handleFeedbackEmailNotification(matchId);
          handleFeedbackSMSNotification(matchId);
        }

        removeArchivedMatch(docId, matchId);
        addNotInterestedMatch(docId, matchId);

        const updatedArchivedData = archivedStartupsData.filter(
          (item) => item.id !== matchId
        );
        setArchivedStartupsData(updatedArchivedData);
        updateLatestActions(
          userRef,
          `Selected Not Interested in ${startupData.formData.company.company} and provided feedback (dashboard)`,
          isMobile
        );
        addToSummary(
          matchId,
          `Selected 'Not Interested' and provided feedback`,
          currentUserId
        );
        setNotInterestedPopUpFromArchived(false);
      } else {
        console.log("User document not found");
      }
    } catch (error) {
      // Handle the error
      console.error(
        "Error handling not interested from archived feedback:",
        error
      );
    }
  };

  const handleFlaggingFromArchivedFeedback = async (docId, matchId, reason) => {
    try {
      storeFlag(matchId, reason);
      const usersCollection = collection(db, "users");

      // Fetch the user document by matching on the "id" field
      const startupDocRef = doc(usersCollection, matchId);
      const startupDocSnap = await getDoc(startupDocRef);

      if (startupDocSnap.exists()) {
        // Access the user data using startupDocSnap.data()
        const startupData = startupDocSnap.data();

        removeArchivedMatch(docId, matchId);
        addNotInterestedMatch(docId, matchId);

        const updatedArchivedData = archivedStartupsData.filter(
          (item) => item.id !== matchId
        );
        setArchivedStartupsData(updatedArchivedData);

        setFlagFromArchivedPopUp(false);
        setNotInterestedPopUpFromArchived(false);
        setThankYouPopUp(true);

        const payload = {
          sender: {
            name: "PRAIRI",
            email: "invite@prairi.com",
          },
          to: [
            {
              name: "Carolina",
              email: "kro.ruz@gmail.com",
            },
            {
              name: "Peter",
              email: "peter@prairi.com",
            },
            //   {name:"hoorieh",
            // email: "hoorieh.23@gmail.com"},
          ],
          subject: "Flagged user",
          textContent: "This user has been flagged",
          templateId: 35,
          params: {
            INVESTOR: docId,
            STARTUP: matchId,
            REASON: reason,
          },
        };

        const { data } = await axios({
          method: "POST",
          url: "https://api.sendinblue.com/v3/smtp/email",
          headers: {
            Accept: "application/json",
            "api-key": process.env.REACT_APP_SENDINBLUE_API_KEY,
            "Content-Type": "application/json",
          },
          data: JSON.stringify(payload),
        });
      } else {
        console.log("User document not found");
      }
    } catch (error) {
      // Handle the error
      console.error("Error handling flagging from archived feedback:", error);
    }
  };

  const handleNotInterestFromInterestedNoFeedback = async (docId, matchId) => {
    try {
      const startupRef = doc(db, "users", matchId);
      const startupDoc = await getDoc(startupRef);
      removeInterestedMatch(docId, matchId);
      addNotInterestedMatch(docId, matchId);
      const updatedInterestedData = interestedStartupsData.filter(
        (item) => item.id !== matchId
      );
      setInterestedStartupsData(updatedInterestedData);
      updateLatestActions(
        userRef,
        `Selected Not Interested in ${
          startupDoc.data().formData.company.company
        } and chose not to provide feedback (dashboard)`,
        isMobile
      );
      addToSummary(
        matchId,
        `Selected 'Not Interested' and chose not to provide feedback`,
        currentUserId
      );
      setNotInterestedPopUp(false);
    } catch (error) {
      // Handle the error
      // console.log("Error updating document:", error);
    }
  };

  const handleNotInterestFromArchivedNoFeedback = async (docId, matchId) => {
    try {
      const startupRef = doc(db, "users", matchId);
      const startupDoc = await getDoc(startupRef);
      removeArchivedMatch(docId, matchId);
      addNotInterestedMatch(docId, matchId);
      const updatedArchivedData = archivedStartupsData.filter(
        (item) => item.id !== matchId
      );
      setArchivedStartupsData(updatedArchivedData);
      updateLatestActions(
        userRef,
        `Selected Not Interested in ${
          startupDoc.data().formData.company.company
        } and chose not to provide feedback (dashboard)`,
        isMobile
      );
      addToSummary(
        matchId,
        `Selected 'Not Interested' and chose not to provide feedback`,
        currentUserId
      );
      setNotInterestedPopUpFromArchived(false);
    } catch (error) {
      // Handle the error
      // console.log("Error updating document:", error);
    }
  };

  const sendShareEmail = async (docId, potentialId) => {
    try {
      const sharedUsersCollection = collection(db, "sharedUsers");
      const startupDocRef = doc(sharedUsersCollection, docId);
      const startupDocSnap = await getDoc(startupDocRef);
      //console.log('send email' + JSON.stringify(currentUser))
      // console.log(docId);
      //console.log(potentialName);
      if (startupDocSnap.exists()) {
        const payload = {
          sender: {
            name: "PRAIRI",
            email: "invite@prairi.com",
          },
          to: [
            {
              name: potentialName,
              email: potentialEmail,
            },
          ],
          templateId: 46,
          params: {
            STARTUPNAME: startupDocSnap.data().formData.company.company,
            INVESTORNAME: currentUserData.contactName,
            STARTUPID: docId,
            NAME: potentialName.split(" ")[0],
            POTENTIALID: potentialId,
          },
        };
        await axios({
          method: "POST",
          url: "https://api.sendinblue.com/v3/smtp/email",
          headers: {
            Accept: "application/json",
            "api-key": process.env.REACT_APP_SENDINBLUE_API_KEY,
            "Content-Type": "application/json",
          },
          data: JSON.stringify(payload),
        });
      }
    } catch (error) {
      // Handle error
      console.log(error);
    }
  };

  const sendShareSMS = async (docId, phone, id) => {
    try {
      const messagesCollection = collection(db, "messages");
      let processedPhone;
      phone.charAt(0) === "+"
        ? (processedPhone = phone)
        : (processedPhone = `+${phone}`);
      const dataToStore = {
        to: processedPhone,
        body: `Hi, ${potentialName.split(" ")[0]}. ${
          currentUserData.contactName
        } has sent you a startup's profile on PRAIRI that you might be interested in. https://prairi.com/startup/${docId}/${id}`,
      };
      await addDoc(messagesCollection, dataToStore);
    } catch (error) {
      console.log(error);
    }
  };

  const handleShare = async (docId) => {
    const startupRef = doc(db, "users", docId);
    const startupDoc = await getDoc(startupRef);
    if (potentialPhone === "" && potentialEmail === "") {
      console.log("missing data");
      setShareError("Please provide an email address or phone number");
      return;
    }
    let processedPhone;
    processedPhone =
      potentialPhone.charAt(0) === "+"
        ? (processedPhone = potentialPhone.replace("+", ""))
        : potentialPhone;

    const formData = startupDoc.data().formData;

    const sharedUserRef = await addDoc(collection(db, "sharedUsers"), {
      formData: formData,
      userId: docId,
      coleagueEmail: currentUserData.email,
      coleaguePhone: currentUserData.phone,
      coleagueContactName: currentUserData.contactName,
      coleagueSmsConsent: currentUserData.smsConsent,
      coleagueEmailConsent: currentUserData.emailConsent,
    });
    const sharedUserId = sharedUserRef.id;

    const docRef = await addDoc(collection(db, "potential"), {
      username: potentialName,
      email: potentialEmail,
      phone: processedPhone,
      category: "investor",
      sharedBy: currentUserId,
      name: potentialName.split(" ")[0],
    });
    const id = docRef.id;
    console.log(id);
    setPotentialId(id);
    sendShareEmail(sharedUserId, id);
    updateLatestActions(
      userRef,
      `Shared startup ${
        startupDoc.data().formData.company.company
      }with another investor`,
      isMobile
    );
    addToSummary(
      docId,
      `Shared your profile with another investor outside of PRAIRI`,
      currentUserId
    );
    sendShareSMS(sharedUserId, potentialPhone, id);
    setTySharePopup(true);
    //console.log("sent?")
  };

  const storeOkPopup = async (docId) => {
    const userRef = doc(db, "users", docId);
    updateLatestActions(
      userRef,
      "Clicked on 'welcome back' popup in db",
      isMobile
    );
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    updateLatestActions(userRef, "Dashboard view", isMobile);
  }, []);

  const redirectToLogin = () => {
    navigate("/login"); // Redirect to the login page
  };

  const redirectToForms = () => {
    //search for current position of the user in the forms
    storeOkPopup(currentUserId);
    navigate("/startupregistration");
  };

  const handleName = (e) => {
    setPotentialName(e.target.value);
  };

  const handleEmail = (e) => {
    setPotentialEmail(e.target.value);
  };

  const handlePhone = (e) => {
    setPotentialPhone(e.target.value);
  };

  useEffect(() => {
    if (!currentUser && dataFetched) {
      redirectToLogin();
    }
  }, [currentUser]);

  useEffect(() => {
    // Adjust the container's height based on the content height
  }, [showInterested, showArchived]);

  const { uid } = currentUser || {};

  const isAdminUser =
    currentUser && currentUser.uid === "A3Go76mJaLT2GeqHsxlYZRCOWur2";

  const isTestInvestor =
    currentUser && currentUser.uid === "63ZaAMsEq6UqenQ8CmFVxho5sZe2";

  if (isTestInvestor) {
    navigate("/investorregistration");
    return null;
  }

  if (!dataFetched) {
    return (
      <div
        className="md:max-w-lg p-2 from-navStart to-navEnd px-4 flex flex-col "
        ref={containerRef}
        style={{ marginTop: navBarHeight + 30 }}
      >
        <Loading />
      </div>
    );
  } else {
    if (isMobile) {
      //I'm so sorry for doing it this way uwu
      return (
        <div>
          <div
            className="md:max-w-lg p-1 from-navStart pt-5 pb-2 to-navEnd px-4 flex flex-col "
            ref={containerRef}
            style={{ marginTop: navBarHeight + 4 }}
          >
            {/* <h1>{JSON.stringify(interestedStartupsData)}</h1> */}

            <div className="">
              {showInterested && (
                <div className="flex space-x-8 justify-center items-start p-2 mb-5">
                  <Badge
                    content={interestedStartupsData.length}
                    color="#303030"
                    border="#92d050"
                  >
                    <button
                      className="
                      font-[sans-serif]  tracking-wide font bg-#2E2E2E  border-[2px]  rounded-md py-1  
                      w-[30vw] max-w-[150px] min-w-[94px]
                      sm:w-[150px] "
                      style={interestedStyle}
                      onClick={handleInterestedClick}
                    >
                      Interested
                    </button>
                  </Badge>

                  <Badge
                    content={archivedStartupsData.length}
                    color="#303030"
                    border="#c8c8c8"
                  >
                    <button
                      className="            
                  font-[sans-serif]  tracking-wide font bg-#2E2E2E border-[2px]  rounded-md py-1                 
                  w-[30vw] max-w-[150px] min-w-[94px]
                  sm:w-[150px] "
                      style={archivedStyle}
                      onClick={handleArchivedClick}
                    >
                      Archived
                    </button>
                  </Badge>
                </div>
              )}
              {showArchived && (
                <div
                  className={`flex space-x-8 justify-center items-start p-2 mb-5 ${
                    shownDeck && "ml-5"
                  }`}
                >
                  <Badge
                    content={interestedStartupsData.length}
                    border="#c8c8c8"
                    color="#303030"
                  >
                    <button
                      className="
                      font-[sans-serif]  tracking-wide font bg-#2E2E2E  border-[2px]  rounded-md py-1  
                      w-[30vw] max-w-[150px] min-w-[94px]
                      sm:w-[150px] "
                      style={interestedStyle}
                      onClick={handleInterestedClick}
                    >
                      Interested
                    </button>
                  </Badge>
                  <Badge
                    content={archivedStartupsData.length}
                    color="#303030"
                    border="#ffc000"
                  >
                    <button
                      className="            
                  font-[sans-serif]  tracking-wide font bg-#2E2E2E border-[2px]  rounded-md py-1                 
                  w-[30vw] max-w-[150px] min-w-[94px]
                  sm:w-[150px] "
                      style={archivedStyle}
                      onClick={handleArchivedClick}
                    >
                      Archived
                    </button>
                  </Badge>
                </div>
              )}

              {showInterested && interestedStartupsData.length > 0 && (
                <div>
                  <h1 className="text-#f2f2f2 font-bold text-center pb-5  ">
                    My Matches
                  </h1>

                  <div>
                    {/* {console.log('interestedStartupsData pre loop', interestedStartupsData)} */}
                    {interestedStartupsData.map((startup) => (
                      <div
                        className="mb-2 items-center flex justify-center"
                        key={startup.id}
                      >
                        <div
                          style={{
                            width: isMobile ? "345px" : "450px", // Default width for the button
                            // Add more media queries and styles as needed
                          }}
                          className="items-center flex mb-2 w-1/3 justify-center"
                        >
                          <StartupButton
                            startup={startup}
                            status="interested"
                            investor={currentUserData}
                            share={() => handleShare(shownStartup.id)}
                            state={
                              currentUserData.formData.company.startupstates
                                .length < usStates.length
                            }
                            notInterested={() =>
                              setNotInterestedPopUp(startup.id)
                            }
                            setEmail={handleEmail}
                            closeCard2={() => setShownStartup(false)}
                            setName={handleName}
                            setPhone={handlePhone}
                            shareError={shareError}
                            isMobile={isMobile}
                            pitchTracking={() =>
                              pitchTracking(startup.id, currentUserId, isMobile)
                            }
                            urlTracking={() =>
                              urlTracking(startup.id, currentUserId, isMobile)
                            }
                          />
                        </div>
                        <div>
                          <Modal
                            border="red"
                            open={notInterestedPopUp === startup.id}
                          >
                            <div>
                              <div className="flex justify-center items-center mb-5 mt-2">
                                <FlagLink
                                  click={() =>
                                    setFlagFromInterestedPopUp(startup.id)
                                  }
                                />
                              </div>
                              <form>
                                <label for="checkboxes-group">
                                  To help this startup so that they may one day
                                  be ready to receive investment from you,
                                  please provide your feedback.
                                  <span className="font-bold">
                                    You will remain anonymous.
                                  </span>
                                </label>
                                <br />
                                <div className="flex mt-4 items-center">
                                  <input
                                    className="mr-5  w-5 h-5"
                                    type="checkbox"
                                    id="no-traction"
                                    name="checkboxes-group"
                                    value="Not enough traction"
                                    onChange={handleNotInterestedReason}
                                  />
                                  <label for="no-traction">
                                    {" "}
                                    Not enough traction
                                  </label>
                                </div>
                                <br />
                                <div className="flex  items-center">
                                  <input
                                    className="mr-5  w-5 h-5"
                                    type="checkbox"
                                    id="team-not-strong"
                                    name="checkboxes-group"
                                    value="Team not strong enough"
                                    onChange={handleNotInterestedReason}
                                  />
                                  <label for="team-not-strong">
                                    {" "}
                                    Team not strong enough
                                  </label>
                                </div>
                                <br />
                                <div className="flex  items-center">
                                  <input
                                    className="mr-5  w-5 h-5"
                                    type="checkbox"
                                    id="bad-timing"
                                    name="checkboxes-group"
                                    value="Bad timing for this idea or market"
                                    onChange={handleNotInterestedReason}
                                  />
                                  <label for="bad-timing">
                                    {" "}
                                    Bad timing for this idea or market
                                  </label>
                                </div>
                                <br />
                                <div className="flex  items-center">
                                  <input
                                    className="mr-5  w-5 h-5"
                                    type="checkbox"
                                    id="competitive-landscape"
                                    name="checkboxes-group"
                                    value="Landscape is too competitive"
                                    onChange={handleNotInterestedReason}
                                  />
                                  <label for="competitive-landscape">
                                    {" "}
                                    Landscape is too competitive
                                  </label>
                                </div>
                                <br />
                                <div className="flex  items-center">
                                  <input
                                    className="mr-5  w-5 h-5"
                                    type="checkbox"
                                    id="higher-capital"
                                    name="checkboxes-group"
                                    value="Much higher capital would be needed to succeed"
                                    onChange={handleNotInterestedReason}
                                  />
                                  <label for="higher-capital">
                                    {" "}
                                    Much higher capital would be needed to
                                    succeed
                                  </label>
                                </div>
                                <br />
                                <div className="flex  items-center">
                                  <input
                                    className="mr-5  w-5 h-5"
                                    type="checkbox"
                                    id="other"
                                    name="checkboxes-group"
                                    value="other"
                                    onChange={handleNotInterestedReason}
                                  />
                                  <label for="other">
                                    {" "}
                                    Other reason:{" "}
                                    <input
                                      type="text"
                                      className="w-32 ml-4 rounded-lg text-black"
                                      id="other-reason"
                                      name="other-reason"
                                      onChange={handleNotInterestedReasonText}
                                    />
                                  </label>
                                </div>
                              </form>
                              <div className="mt-5 mb-5">
                                <TextButton
                                  label="Don't wish to leave feedback"
                                  click={() =>
                                    handleNotInterestFromInterestedNoFeedback(
                                      currentUserId,
                                      startup.id
                                    )
                                  }
                                />
                              </div>
                              <div className="flex justify-between items-center  w-[80%]">
                                <PrairiButton
                                  bgColor="regular"
                                  border="red"
                                  size="md"
                                  rounded="full"
                                  label="Send"
                                  click={() =>
                                    handleNotInterestFromInterestedFeedback(
                                      currentUserId,
                                      startup.id,
                                      notInterestedReasons
                                    )
                                  }
                                />

                                <div className="ml-13">
                                  <TextButton
                                    label="undo"
                                    click={() => setNotInterestedPopUp(false)}
                                  />
                                </div>
                              </div>
                            </div>
                          </Modal>
                          <Modal
                            border="red"
                            open={flagFromInterestedPopUp === startup.id}
                          >
                            <div className=" m-3">
                              <form>
                                <img
                                  src={Flag}
                                  className="h-12 mx-auto block mb-5"
                                  alt="flag"
                                />
                                <label
                                  for="checkboxes-group "
                                  className="text-sm"
                                >
                                  <div className="font-bold ">
                                    Report this profile and we'll look into it.
                                  </div>
                                  PRAIRI personally checks each user before
                                  allowing them to join, but it's possible this
                                  one slipped past us.
                                </label>
                                <div className="flex flex-col mt-5">
                                  <div className="flex mb-4">
                                    <input
                                      type="radio"
                                      id="fake-profile"
                                      name="flagReason"
                                      value="Fake profile"
                                      onChange={handleFlagReason}
                                      className="mr-3 w-5"
                                    />
                                    <label htmlFor="fake-profile">
                                      Fake profile
                                    </label>
                                  </div>
                                  <div className="flex mb-4">
                                    <input
                                      type="radio"
                                      id="scammer"
                                      name="flagReason"
                                      value="Scammer"
                                      onChange={handleFlagReason}
                                      className="mr-3 w-5"
                                    />
                                    <label htmlFor="fake-profile">
                                      Scammer
                                    </label>
                                  </div>
                                  <div className="flex mb-4">
                                    <input
                                      type="radio"
                                      id="abusive"
                                      name="flagReason"
                                      value="Abusive to you or others"
                                      onChange={handleFlagReason}
                                      className="mr-3 w-5"
                                    />
                                    <label htmlFor="fake-profile">
                                      Abusive to you or others
                                    </label>
                                  </div>
                                  <div className="flex mb-4">
                                    <input
                                      type="radio"
                                      id="other"
                                      name="flagReason"
                                      value="Other"
                                      onChange={handleFlagReason}
                                      className="mr-3 w-5"
                                    />
                                    <label htmlFor="other">Other</label>
                                    <input
                                      type="text"
                                      className="w-52 rounded-lg text-black ml-3"
                                      id="otherReason"
                                      name="otherReason"
                                      onChange={(event) =>
                                        setFlagReason(event.target.value)
                                      }
                                    />
                                  </div>
                                </div>
                              </form>
                            </div>
                            <div className="flex w-[80%] justify-between items-center">
                              <PrairiButton
                                bgColor="regular"
                                border="red"
                                size="md"
                                rounded="full"
                                label="Send"
                                click={() =>
                                  handleFlaggingFromInterestedFeedback(
                                    currentUserId,
                                    startup.id,
                                    flagReason
                                  )
                                }
                              />

                              <div className="ml-3">
                                <TextButton
                                  label="back"
                                  click={() =>
                                    setFlagFromInterestedPopUp(false)
                                  }
                                />
                              </div>
                            </div>
                          </Modal>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              )}
              {showInterested &&
                interestedStartups.length === 0 &&
                !isFirstInMobile && (
                  <div>
                    <h1 className="text-#f2f2f2 font-bold text-center pb-5">
                      No matches yet 
                    </h1>

                    <p className="text-[#a5a5a5] ">
                      How often you match is based on the criteria you set in
                      your Data Profile and the startups/investors on our
                      platform that match your criteria (including their
                      location, industry, stage, the amount of investment,
                      etc.).
                    </p>
                  </div>
                )}
              {showInterested &&
                interestedStartups.length === 0 &&
                isFirstInMobile && (
                  <div className="flex flex-col justify-center text-center items-center">
                    <img src={Logo} className="w-[60px] mb-5" alt="logo"></img>
                    <p className="text-[#a5a5a5] mb-4">
                      Great! You’re logged in. You can now close this tab on
                      your phone. 
                    </p>
                    <p className="text-[#a5a5a5] ">We’ll send you a notification (SMS/email)
                      whenever there are startup matches based on your criteria
                      (it will never be more than once in a 24-hour period).</p>
                  </div>
                )}
              {showArchived && archivedStartupsData.length > 0 && (
                <div>
                  <div className="flex flex-col w-full text-center">
                    <h1 className="text-#f2f2f2 font-bold text-center pb-5">
                      My 'View Later' Matches
                    </h1>
                    <p className="mb-2 text-xs">
                      NOTE: These matches may disappear soon since other
                      investors have clicked 'Interested'.
                    </p>
                    <p className="mb-4 text-xs">
                      (All matches disappear after they receive their funding.)
                    </p>
                  </div>
                  <div>
                    {archivedStartupsData.map((startup) => (
                      <div
                        className="mb-2 items-center flex justify-center"
                        key={startup.id}
                      >
                        <div
                          style={{
                            width: "345px", // Default width for the button
                            // Add more media queries and styles as needed
                          }}
                          className="items-center flex justify-center"
                        >
                          <StartupButton
                            startup={startup}
                            status="archived"
                            interested={() => setInterestedPopUp(startup.id)}
                            notInterested={() =>
                              setNotInterestedPopUpFromArchived(startup.id)
                            }
                            investor={currentUserData}
                            share={() => handleShare(startup.id)}
                            setEmail={handleEmail}
                            setName={handleName}
                            setPhone={handlePhone}
                            closeCard2={() => setShownStartup(false)}
                            shareError={shareError}
                            isMobile={isMobile}
                            state={
                              currentUserData.formData.company.startupstates
                                .length < usStates.length
                            }
                            pitchTracking={() =>
                              pitchTracking(startup.id, currentUserId, isMobile)
                            }
                            urlTracking={() =>
                              urlTracking(startup.id, currentUserId, isMobile)
                            }
                          />
                        </div>
                        <div className="">
                          <Modal
                            border="green"
                            open={interestedPopUp === startup.id}
                          >
                            <div className=" m-3">
                              <p>
                                {" "}
                                Great! We’ll let them know and provide you with
                                their contact info.
                              </p>
                            </div>
                            <div className="flex justify-between items-center  w-[80%]">
                              <PrairiButton
                                bgColor="regular"
                                border="green"
                                size="md"
                                rounded="full"
                                label="OK"
                                click={() => handleInterest(startup.id)}
                              />
                              <TextButton
                                label="undo"
                                click={() => setInterestedPopUp(false)}
                              />
                            </div>
                          </Modal>
                        </div>
                        <div>
                          <Modal
                            border="red"
                            open={notInterestedPopUpFromArchived === startup.id}
                          >
                            <div>
                              <div className="flex justify-center items-center mb-5 mt-2">
                                <FlagLink
                                  click={() =>
                                    setFlagFromArchivedPopUp(startup.id)
                                  }
                                />
                              </div>
                              <form>
                                <label for="checkboxes-group">
                                  To help this startup so that they may one day
                                  be ready to receive investment from you,
                                  please provide your feedback.
                                  <span className="font-bold">
                                    You will remain anonymous.
                                  </span>
                                </label>
                                <br />
                                <div className="flex mt-4 items-center">
                                  <input
                                    className="mr-5  w-5 h-5"
                                    type="checkbox"
                                    id="no-traction"
                                    name="checkboxes-group"
                                    value="Not enough traction"
                                    onChange={handleNotInterestedReason}
                                  />
                                  <label for="no-traction">
                                    {" "}
                                    Not enough traction
                                  </label>
                                </div>
                                <br />
                                <div className="flex  items-center">
                                  <input
                                    className="mr-5  w-5 h-5"
                                    type="checkbox"
                                    id="team-not-strong"
                                    name="checkboxes-group"
                                    value="Team not strong enough"
                                    onChange={handleNotInterestedReason}
                                  />
                                  <label for="team-not-strong">
                                    {" "}
                                    Team not strong enough
                                  </label>
                                </div>
                                <br />
                                <div className="flex  items-center">
                                  <input
                                    className="mr-5  w-5 h-5"
                                    type="checkbox"
                                    id="bad-timing"
                                    name="checkboxes-group"
                                    value="Bad timing for this idea or market"
                                    onChange={handleNotInterestedReason}
                                  />
                                  <label for="bad-timing">
                                    {" "}
                                    Bad timing for this idea or market
                                  </label>
                                </div>
                                <br />
                                <div className="flex  items-center">
                                  <input
                                    className="mr-5  w-5 h-5"
                                    type="checkbox"
                                    id="competitive-landscape"
                                    name="checkboxes-group"
                                    value="Landscape is too competitive"
                                    onChange={handleNotInterestedReason}
                                  />
                                  <label for="competitive-landscape">
                                    {" "}
                                    Landscape is too competitive
                                  </label>
                                </div>
                                <br />
                                <div className="flex  items-center">
                                  <input
                                    className="mr-5  w-5 h-5"
                                    type="checkbox"
                                    id="higher-capital"
                                    name="checkboxes-group"
                                    value="Much higher capital would be needed to succeed"
                                    onChange={handleNotInterestedReason}
                                  />
                                  <label for="higher-capital">
                                    {" "}
                                    Much higher capital would be needed to
                                    succeed
                                  </label>
                                </div>
                                <br />
                                <div className="flex  items-center">
                                  <input
                                    className="mr-5  w-5 h-5"
                                    type="checkbox"
                                    id="other"
                                    name="checkboxes-group"
                                    value="other"
                                    onChange={handleNotInterestedReason}
                                  />
                                  <label for="other">
                                    {" "}
                                    Other reason:{" "}
                                    <input
                                      type="text"
                                      className="w-32 ml-3 rounded-lg text-black"
                                      id="other-reason"
                                      name="other-reason"
                                      onChange={handleNotInterestedReasonText}
                                    />
                                  </label>
                                </div>
                              </form>
                              <div className="mt-5 mb-5">
                                <TextButton
                                  label="Don't wish to leave feedback"
                                  click={() =>
                                    handleNotInterestFromArchivedNoFeedback(
                                      currentUserId,
                                      startup.id
                                    )
                                  }
                                />
                              </div>
                              <div className="flex justify-between items-center  w-[80%]">
                                <PrairiButton
                                  bgColor="regular"
                                  border="red"
                                  size="md"
                                  rounded="full"
                                  label="Send"
                                  click={() =>
                                    handleNotInterestFromArchivedFeedback(
                                      currentUserId,
                                      startup.id,
                                      notInterestedReasons
                                    )
                                  }
                                />

                                <div className="ml-13">
                                  <TextButton
                                    label="undo"
                                    click={() =>
                                      setNotInterestedPopUpFromArchived(false)
                                    }
                                  />
                                </div>
                              </div>
                            </div>
                          </Modal>
                          <Modal
                            border="red"
                            open={flagFromArchivedPopUp === startup.id}
                          >
                            <div className=" m-3">
                              <form>
                                <img
                                  src={Flag}
                                  className="h-12 mx-auto block mb-5"
                                  alt="flag"
                                />
                                <label
                                  for="checkboxes-group "
                                  className="text-sm"
                                >
                                  <div className="font-bold ">
                                    Report this profile and we'll look into it.
                                  </div>
                                  PRAIRI personally checks each user before
                                  allowing them to join, but it's possible this
                                  one slipped past us.
                                </label>
                                <div className="flex flex-col mt-5">
                                  <div className="flex mb-4">
                                    <input
                                      type="radio"
                                      id="fake-profile"
                                      name="flagReason"
                                      value="Fake profile"
                                      onChange={handleFlagReason}
                                      className="mr-3 w-5"
                                    />
                                    <label htmlFor="fake-profile">
                                      Fake profile
                                    </label>
                                  </div>
                                  <div className="flex mb-4">
                                    <input
                                      type="radio"
                                      id="scammer"
                                      name="flagReason"
                                      value="Scammer"
                                      onChange={handleFlagReason}
                                      className="mr-3 w-5"
                                    />
                                    <label htmlFor="fake-profile">
                                      Scammer
                                    </label>
                                  </div>
                                  <div className="flex mb-4">
                                    <input
                                      type="radio"
                                      id="abusive"
                                      name="flagReason"
                                      value="Abusive to you or others"
                                      onChange={handleFlagReason}
                                      className="mr-3 w-5"
                                    />
                                    <label htmlFor="fake-profile">
                                      Abusive to you or others
                                    </label>
                                  </div>
                                  <div className="flex mb-4">
                                    <input
                                      type="radio"
                                      id="other"
                                      name="flagReason"
                                      value="Other"
                                      onChange={handleFlagReason}
                                      className="mr-3 w-5"
                                    />
                                    <label htmlFor="other">Other</label>
                                    <input
                                      type="text"
                                      className="w-52 rounded-lg text-black ml-3"
                                      id="otherReason"
                                      name="otherReason"
                                      onChange={(event) =>
                                        setFlagReason(event.target.value)
                                      }
                                    />
                                  </div>
                                </div>
                              </form>
                            </div>
                            <div className="flex w-[80%] justify-between items-center">
                              <PrairiButton
                                bgColor="regular"
                                border="red"
                                size="md"
                                rounded="full"
                                label="Send"
                                click={() =>
                                  handleFlaggingFromArchivedFeedback(
                                    currentUserId,
                                    startup.id,
                                    flagReason
                                  )
                                }
                              />

                              <div className="ml-3">
                                <TextButton
                                  label="back"
                                  click={() => setFlagFromArchivedPopUp(false)}
                                />
                              </div>
                            </div>
                          </Modal>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              )}
              {showArchived && archivedStartups.length === 0 && (
                <div>
                  <h1 className="text-#f2f2f2 font-bold text-center pb-5">
                    No archived matches
                  </h1>
                </div>
              )}
            </div>

            <Modal border="no-border" open={thankYouPopUp}>
              <div className="py-5">
                <img
                  src={ThankYou}
                  alt="Thank you"
                  className="w-[80%] mx-auto mb-6"
                />
                <div className="flex place-content-center">
                  <PrairiButton
                    bgColor="regular"
                    border="silver"
                    size="sm"
                    rounded="soft"
                    label="OK"
                    click={() => setThankYouPopUp(false)}
                  />
                </div>
              </div>
            </Modal>

            <Modal border="silver" open={tySharePopup} size="sm-responsive">
              <div className="pt-5 flex flex-col items-center">
                <img src={Logo} className="w-1/4 mb-2 "></img>
                <div>Thanks for sharing!</div>
                <PrairiButton
                  click={() => setTySharePopup(false)}
                  border="silver"
                  label="Sure"
                  rounded="soft"
                  size="sm"
                />
              </div>
            </Modal>

            {dataFetched && (
              <CompleteYourProfile
                open={
                  !currentUserData.formData ||
                  !currentUserData.formData.matching.completed
                }
                click={redirectToForms}
              />
            )}
            {pendingStartups.length > 0 && ( //don't forget to add isMobile check when adding pitch deck to desktop version
              <div className=" flex justify-center items-center mt-8 lg:ml-3 ml-2 ">
                <MatchesReminder click={seeNotifications} />
              </div>
            )}
          </div>
        </div>
      );
    } else {
      return (
        <div
          className={`flex items-start w-full justify-left  ${
            (shownDeck || shownArchivedDeck) && "flex-col"
          }`}
        >
          <div
            className={` ${
              (showInterested ? !shownDeck : !shownArchivedDeck) &&
              "bg-rainbowBgVertical"
            }`}
            style={{width: (deviceWidth/4)*1.1}}
          >
            <div
              className={`md:max-w-lg  mx-[1px] bg-[#262626]  from-navStart pt-5  to-navEnd px-4 flex flex-col h-auto`}
              ref={containerRef}
              style={{ marginTop: navBarHeight,
                height: showInterested?(shownDeck?80:(deviceHeight-navBarHeight)):(shownArchivedDeck?80:(deviceHeight-navBarHeight))
                //height: deviceHeight-navBarHeight,

                //  paddingBottom: (showInterested ? (!shownDeck && !shownStartup) : (!shownArchivedDeck && !shownArchivedStartup)) && deviceHeight*(isLarge?0.5:0.34) 
                
                }}
            >
              {
                <div className=" flex flex-col items-center justify-center ">
                  <div className="px-1 bg-[#262626]">
                    {showInterested && (
                      <div
                        className={`flex space-x-8  justify-center items-start p-2 mb-5 `}
                      >
                        
                        <Badge
                          content={interestedStartupsData.length}
                          color="#303030"
                          border="#92d050"
                        >
                          <button
                            className="
                      font-[sans-serif]  tracking-wide font bg-#2E2E2E  border-[2px]  rounded-md py-1  
                      w-[30vw] max-w-[150px] min-w-[94px]
                      sm:w-[150px] "
                            style={interestedStyle}
                            onClick={handleInterestedClick}
                          >
                            Interested
                          </button>
                        </Badge>

                        <Badge
                          content={archivedStartupsData.length}
                          color="#303030"
                          border="#c8c8c8"
                        >
                          <button
                            className="            
                  font-[sans-serif]  tracking-wide font bg-#2E2E2E border-[2px]  rounded-md py-1                 
                  w-[30vw] max-w-[150px] min-w-[94px]
                  sm:w-[150px] "
                            style={archivedStyle}
                            onClick={handleArchivedClick}
                          >
                            Archived
                          </button>
                        </Badge>
                      </div>
                    )}
                    {showArchived && (
                      <div className="flex space-x-8 justify-center items-start p-2 mb-5">
                        <Badge
                          content={interestedStartupsData.length}
                          border="#c8c8c8"
                          color="#303030"
                        >
                          <button
                            className="
                      font-[sans-serif]  tracking-wide font bg-#2E2E2E  border-[2px]  rounded-md py-1  
                      w-[30vw] max-w-[150px] min-w-[94px]
                      sm:w-[150px] "
                            style={interestedStyle}
                            onClick={handleInterestedClick}
                          >
                            Interested
                          </button>
                        </Badge>
                        <Badge
                          content={archivedStartupsData.length}
                          color="#303030"
                          border="#ffc000"
                        >
                          <button
                            className="            
                  font-[sans-serif]  tracking-wide font bg-#2E2E2E border-[2px]  rounded-md py-1                 
                  w-[30vw] max-w-[150px] min-w-[94px]
                  sm:w-[150px] "
                            style={archivedStyle}
                            onClick={handleArchivedClick}
                          >
                            Archived
                          </button>
                        </Badge>
                      </div>
                    )}

                    {showInterested &&
                      interestedStartupsData.length > 0 &&
                      !shownDeck && (
                        <div className="">
                          <h1 className="text-#f2f2f2 font-bold text-center pb-5">
                            My Matches
                          </h1>
                          <div>
                            {/* {console.log('interestedStartupsData pre loop', interestedStartupsData)} */}
                            {interestedStartupsData.map((startup) => (
                              <div
                                className="mb-2 items-center flex justify-center"
                                key={startup.id}
                              >
                                <div
                                  style={{
                                    width: deviceWidth/4, // Default width for the button
                                    // Add more media queries and styles as needed
                                  }}
                                  className="items-center flex mb-2 w-1/3 justify-center"
                                >
                                  <StartupButton
                                    startup={startup}
                                    status="interested"
                                    investor={currentUserData}
                                    share={() => handleShare(startup.id)}
                                    state={
                                      currentUserData.formData.company
                                        .startupstates.length < usStates.length
                                    }
                                    notInterested={() =>
                                      setNotInterestedPopUp(startup.id)
                                    }
                                    setEmail={handleEmail}
                                    setName={handleName}
                                    setPhone={handlePhone}
                                    closeCard2={() => setShownStartup(false)}
                                    shareError={shareError}
                                    isMobile={isMobile}
                                    pitchTracking={() =>
                                      pitchTracking(
                                        startup.id,
                                        currentUserId,
                                        isMobile
                                      )
                                    }
                                    urlTracking={() =>
                                      urlTracking(
                                        startup.id,
                                        currentUserId,
                                        isMobile
                                      )
                                    }
                                    desktopClick={() =>
                                      handleDesktopClick(startup)
                                    }
                                  />
                                </div>
                                <div>
                                  <Modal
                                    border="red"
                                    open={notInterestedPopUp === startup.id}
                                  >
                                    <div>
                                      <div className="flex justify-center items-center mb-5 mt-2">
                                        <FlagLink
                                          click={() =>
                                            setFlagFromInterestedPopUp(
                                              startup.id
                                            )
                                          }
                                        />
                                      </div>
                                      <form>
                                        <label for="checkboxes-group">
                                          To help this startup so that they may
                                          one day be ready to receive investment
                                          from you, please provide your
                                          feedback.
                                          <span className="font-bold">
                                            You will remain anonymous.
                                          </span>
                                        </label>
                                        <br />
                                        <div className="flex mt-4 items-center">
                                          <input
                                            className="mr-5  w-5 h-5"
                                            type="checkbox"
                                            id="no-traction"
                                            name="checkboxes-group"
                                            value="Not enough traction"
                                            onChange={handleNotInterestedReason}
                                          />
                                          <label for="no-traction">
                                            {" "}
                                            Not enough traction
                                          </label>
                                        </div>
                                        <br />
                                        <div className="flex  items-center">
                                          <input
                                            className="mr-5  w-5 h-5"
                                            type="checkbox"
                                            id="team-not-strong"
                                            name="checkboxes-group"
                                            value="Team not strong enough"
                                            onChange={handleNotInterestedReason}
                                          />
                                          <label for="team-not-strong">
                                            {" "}
                                            Team not strong enough
                                          </label>
                                        </div>
                                        <br />
                                        <div className="flex  items-center">
                                          <input
                                            className="mr-5  w-5 h-5"
                                            type="checkbox"
                                            id="bad-timing"
                                            name="checkboxes-group"
                                            value="Bad timing for this idea or market"
                                            onChange={handleNotInterestedReason}
                                          />
                                          <label for="bad-timing">
                                            {" "}
                                            Bad timing for this idea or market
                                          </label>
                                        </div>
                                        <br />
                                        <div className="flex  items-center">
                                          <input
                                            className="mr-5  w-5 h-5"
                                            type="checkbox"
                                            id="competitive-landscape"
                                            name="checkboxes-group"
                                            value="Landscape is too competitive"
                                            onChange={handleNotInterestedReason}
                                          />
                                          <label for="competitive-landscape">
                                            {" "}
                                            Landscape is too competitive
                                          </label>
                                        </div>
                                        <br />
                                        <div className="flex  items-center">
                                          <input
                                            className="mr-5  w-5 h-5"
                                            type="checkbox"
                                            id="higher-capital"
                                            name="checkboxes-group"
                                            value="Much higher capital would be needed to succeed"
                                            onChange={handleNotInterestedReason}
                                          />
                                          <label for="higher-capital">
                                            {" "}
                                            Much higher capital would be needed
                                            to succeed
                                          </label>
                                        </div>
                                        <br />
                                        <div className="flex  items-center">
                                          <input
                                            className="mr-5  w-5 h-5"
                                            type="checkbox"
                                            id="other"
                                            name="checkboxes-group"
                                            value="other"
                                            onChange={handleNotInterestedReason}
                                          />
                                          <label for="other">
                                            {" "}
                                            Other reason:{" "}
                                            <input
                                              type="text"
                                              className="w-32 ml-4 rounded-lg text-black"
                                              id="other-reason"
                                              name="other-reason"
                                              onChange={
                                                handleNotInterestedReasonText
                                              }
                                            />
                                          </label>
                                        </div>
                                      </form>
                                      <div className="mt-5 mb-5">
                                        <TextButton
                                          label="Don't wish to leave feedback"
                                          click={() =>
                                            handleNotInterestFromInterestedNoFeedback(
                                              currentUserId,
                                              startup.id
                                            )
                                          }
                                        />
                                      </div>
                                      <div className="flex justify-between items-center  w-[80%]">
                                        <PrairiButton
                                          bgColor="regular"
                                          border="red"
                                          size="md"
                                          rounded="full"
                                          label="Send"
                                          click={() =>
                                            handleNotInterestFromInterestedFeedback(
                                              currentUserId,
                                              startup.id,
                                              notInterestedReasons
                                            )
                                          }
                                        />

                                        <div className="ml-13">
                                          <TextButton
                                            label="undo"
                                            click={() =>
                                              setNotInterestedPopUp(false)
                                            }
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </Modal>
                                  <Modal
                                    border="red"
                                    open={
                                      flagFromInterestedPopUp === startup.id
                                    }
                                  >
                                    <div className=" m-3">
                                      <form>
                                        <img
                                          src={Flag}
                                          className="h-12 mx-auto block mb-5"
                                          alt="flag"
                                        />
                                        <label
                                          for="checkboxes-group "
                                          className="text-sm"
                                        >
                                          <div className="font-bold ">
                                            Report this profile and we'll look
                                            into it.
                                          </div>
                                          PRAIRI personally checks each user
                                          before allowing them to join, but it's
                                          possible this one slipped past us.
                                        </label>
                                        <div className="flex flex-col mt-5">
                                          <div className="flex mb-4">
                                            <input
                                              type="radio"
                                              id="fake-profile"
                                              name="flagReason"
                                              value="Fake profile"
                                              onChange={handleFlagReason}
                                              className="mr-3 w-5"
                                            />
                                            <label htmlFor="fake-profile">
                                              Fake profile
                                            </label>
                                          </div>
                                          <div className="flex mb-4">
                                            <input
                                              type="radio"
                                              id="scammer"
                                              name="flagReason"
                                              value="Scammer"
                                              onChange={handleFlagReason}
                                              className="mr-3 w-5"
                                            />
                                            <label htmlFor="fake-profile">
                                              Scammer
                                            </label>
                                          </div>
                                          <div className="flex mb-4">
                                            <input
                                              type="radio"
                                              id="abusive"
                                              name="flagReason"
                                              value="Abusive to you or others"
                                              onChange={handleFlagReason}
                                              className="mr-3 w-5"
                                            />
                                            <label htmlFor="fake-profile">
                                              Abusive to you or others
                                            </label>
                                          </div>
                                          <div className="flex mb-4">
                                            <input
                                              type="radio"
                                              id="other"
                                              name="flagReason"
                                              value="Other"
                                              onChange={handleFlagReason}
                                              className="mr-3 w-5"
                                            />
                                            <label htmlFor="other">Other</label>
                                            <input
                                              type="text"
                                              className="w-52 rounded-lg text-black ml-3"
                                              id="otherReason"
                                              name="otherReason"
                                              onChange={(event) =>
                                                setFlagReason(
                                                  event.target.value
                                                )
                                              }
                                            />
                                          </div>
                                        </div>
                                      </form>
                                    </div>
                                    <div className="flex w-[80%] justify-between items-center">
                                      <PrairiButton
                                        bgColor="regular"
                                        border="red"
                                        size="md"
                                        rounded="full"
                                        label="Send"
                                        click={() =>
                                          handleFlaggingFromInterestedFeedback(
                                            currentUserId,
                                            startup.id,
                                            flagReason
                                          )
                                        }
                                      />

                                      <div className="ml-3">
                                        <TextButton
                                          label="back"
                                          click={() =>
                                            setFlagFromInterestedPopUp(false)
                                          }
                                        />
                                      </div>
                                    </div>
                                  </Modal>
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                      )}
                    {showInterested && interestedStartups.length === 0 && (
                      <div className="flex flex-col items-center justify-center " style={{width: deviceWidth/4}}>
                        <h1 className="text-#f2f2f2 font-bold text-center pb-5">
                          No matches yet
                        </h1>
                        <p className="text-[#a5a5a5] ">
                          How often you match is based on the criteria you set
                          in your Data Profile and the startups/investors on our
                          platform that match your criteria (including their
                          location, industry, stage, the amount of investment,
                          etc.).
                        </p>
                      </div>
                    )}
                    {showArchived &&
                      archivedStartupsData.length > 0 &&
                      !shownArchivedDeck && (
                        <div className="flex flex-col items-center justify-center ">
                          <div className="text-center justify-center flex flex-col " style={{width: deviceWidth/4}}>
                            <h2 className="text-#f2f2f2 font-bold text-center pb-5">
                              My 'View Later' Matches
                            </h2>
                            <p className="mb-2 text-xs">
                              NOTE: These matches may disappear soon since other
                              investors have clicked 'Interested'.
                            </p>
                            <p className="mb-8 text-xs">
                              (All matches disappear after they receive their
                              funding.)
                            </p>
                          </div>
                          <div>
                            {/* {console.log('archivedStartupsData pre loop', archivedStartupsData)} */}
                            {archivedStartupsData.map((startup) => (
                              <div
                                className="mb-2 items-center flex justify-center"
                                key={startup.id}
                              >
                                <div
                                  style={{
                                    width: deviceWidth/4, // Default width for the button
                                    // Add more media queries and styles as needed
                                  }}
                                  className="items-center flex justify-center"
                                >
                                  <StartupButton
                                    startup={startup}
                                    status="archived"
                                    interested={() =>
                                      setInterestedPopUp(startup.id)
                                    }
                                    notInterested={() =>
                                      setNotInterestedPopUpFromArchived(
                                        startup.id
                                      )
                                    }
                                    investor={currentUserData}
                                    share={() => handleShare(startup.id)}
                                    setEmail={handleEmail}
                                    setName={handleName}
                                    setPhone={handlePhone}
                                    shareError={shareError}
                                    isMobile={isMobile}
                                    state={
                                      currentUserData.formData.company
                                        .startupstates.length < usStates.length
                                    }
                                    pitchTracking={() =>
                                      pitchTracking(
                                        startup.id,
                                        currentUserId,
                                        isMobile
                                      )
                                    }
                                    urlTracking={() =>
                                      urlTracking(
                                        startup.id,
                                        currentUserId,
                                        isMobile
                                      )
                                    }
                                    desktopClick={() =>
                                      handleDesktopClick(startup)
                                    }
                                  />
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                      )}
                    {showArchived && archivedStartups.length === 0 && (
                      <div className="" style={{width: deviceWidth/4}}>
                        <h1 className="text-#f2f2f2 font-bold text-center pb-5">
                          No archived matches
                        </h1>
                      </div>
                    )}
                  </div>
                </div>
              }

              <div>
                <Modal
                  border="green"
                  open={interestedPopUp === shownArchivedStartup.id}
                >
                  <div className=" m-3">
                    <p>
                      {" "}
                      Great! We’ll let them know and provide you with their
                      contact info.
                    </p>
                  </div>
                  <div className="flex justify-between items-center  w-[80%]">
                    <PrairiButton
                      bgColor="regular"
                      border="green"
                      size="md"
                      rounded="full"
                      label="OK"
                      click={() => handleInterest(shownArchivedStartup.id)}
                    />
                    <TextButton
                      label="undo"
                      click={() => setInterestedPopUp(false)}
                    />
                  </div>
                </Modal>

                <Modal
                  border="red"
                  open={
                    notInterestedPopUpFromArchived === shownArchivedStartup.id
                  }
                >
                  <div>
                    <div className="flex justify-center items-center mb-5 mt-2">
                      <FlagLink
                        click={() =>
                          setFlagFromArchivedPopUp(shownArchivedStartup.id)
                        }
                      />
                    </div>
                    <form>
                      <label for="checkboxes-group">
                        To help this startup so that they may one day be ready
                        to receive investment from you, please provide your
                        feedback.
                        <span className="font-bold">
                          You will remain anonymous.
                        </span>
                      </label>
                      <br />
                      <div className="flex mt-4 items-center">
                        <input
                          className="mr-5  w-5 h-5"
                          type="checkbox"
                          id="no-traction"
                          name="checkboxes-group"
                          value="Not enough traction"
                          onChange={handleNotInterestedReason}
                        />
                        <label for="no-traction"> Not enough traction</label>
                      </div>
                      <br />
                      <div className="flex  items-center">
                        <input
                          className="mr-5  w-5 h-5"
                          type="checkbox"
                          id="team-not-strong"
                          name="checkboxes-group"
                          value="Team not strong enough"
                          onChange={handleNotInterestedReason}
                        />
                        <label for="team-not-strong">
                          {" "}
                          Team not strong enough
                        </label>
                      </div>
                      <br />
                      <div className="flex  items-center">
                        <input
                          className="mr-5  w-5 h-5"
                          type="checkbox"
                          id="bad-timing"
                          name="checkboxes-group"
                          value="Bad timing for this idea or market"
                          onChange={handleNotInterestedReason}
                        />
                        <label for="bad-timing">
                          {" "}
                          Bad timing for this idea or market
                        </label>
                      </div>
                      <br />
                      <div className="flex  items-center">
                        <input
                          className="mr-5  w-5 h-5"
                          type="checkbox"
                          id="competitive-landscape"
                          name="checkboxes-group"
                          value="Landscape is too competitive"
                          onChange={handleNotInterestedReason}
                        />
                        <label for="competitive-landscape">
                          {" "}
                          Landscape is too competitive
                        </label>
                      </div>
                      <br />
                      <div className="flex  items-center">
                        <input
                          className="mr-5  w-5 h-5"
                          type="checkbox"
                          id="higher-capital"
                          name="checkboxes-group"
                          value="Much higher capital would be needed to succeed"
                          onChange={handleNotInterestedReason}
                        />
                        <label for="higher-capital">
                          {" "}
                          Much higher capital would be needed to succeed
                        </label>
                      </div>
                      <br />
                      <div className="flex  items-center">
                        <input
                          className="mr-5  w-5 h-5"
                          type="checkbox"
                          id="other"
                          name="checkboxes-group"
                          value="other"
                          onChange={handleNotInterestedReason}
                        />
                        <label for="other">
                          {" "}
                          Other reason:{" "}
                          <input
                            type="text"
                            className="w-32 ml-3 rounded-lg text-black"
                            id="other-reason"
                            name="other-reason"
                            onChange={handleNotInterestedReasonText}
                          />
                        </label>
                      </div>
                    </form>
                    <div className="mt-5 mb-5">
                      <TextButton
                        label="Don't wish to leave feedback"
                        click={() =>
                          handleNotInterestFromArchivedNoFeedback(
                            currentUserId,
                            shownArchivedStartup.id
                          )
                        }
                      />
                    </div>
                    <div className="flex justify-between items-center  w-[80%]">
                      <PrairiButton
                        bgColor="regular"
                        border="red"
                        size="md"
                        rounded="full"
                        label="Send"
                        click={() =>
                          handleNotInterestFromArchivedFeedback(
                            currentUserId,
                            shownArchivedStartup.id,
                            notInterestedReasons
                          )
                        }
                      />

                      <div className="ml-13">
                        <TextButton
                          label="undo"
                          click={() => setNotInterestedPopUpFromArchived(false)}
                        />
                      </div>
                    </div>
                  </div>
                </Modal>
                <Modal
                  border="red"
                  open={flagFromArchivedPopUp === shownArchivedStartup.id}
                >
                  <div className=" m-3">
                    <form>
                      <img
                        src={Flag}
                        className="h-12 mx-auto block mb-5"
                        alt="flag"
                      />
                      <label for="checkboxes-group " className="text-sm">
                        <div className="font-bold ">
                          Report this profile and we'll look into it.
                        </div>
                        PRAIRI personally checks each user before allowing them
                        to join, but it's possible this one slipped past us.
                      </label>
                      <div className="flex flex-col mt-5">
                        <div className="flex mb-4">
                          <input
                            type="radio"
                            id="fake-profile"
                            name="flagReason"
                            value="Fake profile"
                            onChange={handleFlagReason}
                            className="mr-3 w-5"
                          />
                          <label htmlFor="fake-profile">Fake profile</label>
                        </div>
                        <div className="flex mb-4">
                          <input
                            type="radio"
                            id="scammer"
                            name="flagReason"
                            value="Scammer"
                            onChange={handleFlagReason}
                            className="mr-3 w-5"
                          />
                          <label htmlFor="fake-profile">Scammer</label>
                        </div>
                        <div className="flex mb-4">
                          <input
                            type="radio"
                            id="abusive"
                            name="flagReason"
                            value="Abusive to you or others"
                            onChange={handleFlagReason}
                            className="mr-3 w-5"
                          />
                          <label htmlFor="fake-profile">
                            Abusive to you or others
                          </label>
                        </div>
                        <div className="flex mb-4">
                          <input
                            type="radio"
                            id="other"
                            name="flagReason"
                            value="Other"
                            onChange={handleFlagReason}
                            className="mr-3 w-5"
                          />
                          <label htmlFor="other">Other</label>
                          <input
                            type="text"
                            className="w-52 rounded-lg text-black ml-3"
                            id="otherReason"
                            name="otherReason"
                            onChange={(event) =>
                              setFlagReason(event.target.value)
                            }
                          />
                        </div>
                      </div>
                    </form>
                  </div>
                  <div className="flex w-[80%] justify-between items-center">
                    <PrairiButton
                      bgColor="regular"
                      border="red"
                      size="md"
                      rounded="full"
                      label="Send"
                      click={() =>
                        handleFlaggingFromArchivedFeedback(
                          currentUserId,
                          shownArchivedStartup.id,
                          flagReason
                        )
                      }
                    />

                    <div className="ml-3">
                      <TextButton
                        label="back"
                        click={() => setFlagFromArchivedPopUp(false)}
                      />
                    </div>
                  </div>
                </Modal>
              </div>

              <Modal border="no-border" open={thankYouPopUp}>
                <div className="py-5">
                  <img
                    src={ThankYou}
                    alt="Thank you"
                    className="w-[80%] mx-auto mb-6"
                  />
                  <div className="flex place-content-center">
                    <PrairiButton
                      bgColor="regular"
                      border="silver"
                      size="sm"
                      rounded="soft"
                      label="OK"
                      click={() => setThankYouPopUp(false)}
                    />
                  </div>
                </div>
              </Modal>

              <Modal border="silver" open={tySharePopup} size="sm-responsive">
                <div className="pt-5 flex flex-col items-center">
                  <img src={Logo} className="w-1/4 mb-2 "></img>
                  <div>Thanks for sharing!</div>
                  <PrairiButton
                    click={() => setTySharePopup(false)}
                    border="silver"
                    label="Sure"
                    rounded="soft"
                    size="sm"
                  />
                </div>
              </Modal>

              {dataFetched && (
                <CompleteYourProfile
                  open={
                    !currentUserData.formData ||
                    !currentUserData.formData.matching.completed
                  }
                  click={redirectToForms}
                />
              )}
              {pendingStartups.length > 0 &&
                !shownDeck &&
                !shownArchivedDeck && ( //don't forget to add isMobile check when adding pitch deck to desktop version
                  <div className=" flex justify-center items-center mt-8  ">
                    <MatchesReminder click={seeNotifications} />
                  </div>
                )}
            </div>
          </div>

          {!shownStartup && !shownArchivedStartup && (showArchived?archivedStartups.length>0:interestedStartups.length>0) && (
            <div className="flex justify-center items-center w-full h-full ">
              <div
                className="flex  bg-[#262626] items-center justify-center w-full h-full p-1"
                style={{ paddingTop: 3.5 * navBarHeight }}
              >
                <div className="w-full flex justify-center items-center h-full">
                  <p className="pt-12 text-lg text-silverPrairi">
                    Select a match on the left to view profile 
                  </p>
                </div>
              </div>
            </div>
          )}

{ (showArchived?archivedStartups.length===0:interestedStartups.length===0) && (
            <div className="flex justify-center items-center w-full h-full ">
              <div
                className="flex  bg-[#262626] items-center justify-center  h-full p-1"
                style={{ paddingTop: 3.5 * navBarHeight }}
              >
                <div className="flex-col w-full text-center text-silverPrairi flex justify-center items-center h-full">
                  <p className="">You’ll be notified via email/SMS whenever there’s a match</p>
                </div>
              </div>
            </div>
          )}

          {(showInterested
            ? shownStartup && !shownDeck
            : shownArchivedStartup && !shownArchivedDeck) && (
            <div className=" w-full">
              <div
                className="flex w-full bg-[#262626] pt-2 mx-[0.7px] relative"
                style={{ marginTop: navBarHeight }}
              >
                <div
                  className={`p-2  overflow-y-auto w-1/3` } style={{ maxHeight: deviceHeight*0.9, paddingBottom: showArchived?deviceHeight*0.15:0 }}
                >
                  <StartupProfile
                    startup={
                      showInterested ? shownStartup : shownArchivedStartup
                    }
                    dot={`${showInterested ? "green" : "yellow"}`}
                    share={() =>
                      handleShare(
                        showInterested
                          ? shownStartup.id
                          : shownArchivedStartup.id
                      )
                    }
                    state={
                      currentUserData.formData.company.startupstates.length <
                      usStates.length
                    }
                    notInterested={() =>
                      setNotInterestedPopUp(
                        showInterested
                          ? shownStartup.id
                          : shownArchivedStartup.id
                      )
                    }
                    videoPitchTracking={() =>
                      handleVideoPitchTracking(
                        showInterested
                          ? shownStartup.id
                          : shownArchivedStartup.id,
                        currentUserId,
                        isMobile,
                        showInterested
                          ? shownStartup.formData.company.videoPitch
                          : shownArchivedStartup.formData.company.videoPitch
                      )
                    }
                    pitchTracking={() =>
                      handlePitchTracking(
                        showInterested
                          ? shownStartup.id
                          : shownArchivedStartup.id,
                        currentUserId,
                        isMobile,
                        showInterested
                          ? shownStartup.formData.company.videoPitch
                          : shownArchivedStartup.formData.company.videoPitch
                      )
                    }
                    //  viewer={(showInterested?shownStartup.formData.company.deck.includes("firebasestorage"?"deck":"video"):shownArchivedStartup.formData.company.deck.includes("firebasestorage"?"deck":"video"))}
                    investor={currentUserData}
                    setEmail={handleEmail}
                    setName={handleName}
                    setPhone={handlePhone}
                    shareError={shareError}
                    isMobile={isMobile}
                  />
                </div>
                {
                  <div className="w-2/3 pt-3 relative">
                    <Viewer
                      src={
                        showInterested ? interestedViewerSrc : archivedViewerSrc
                      }
                      blur
                      h={deviceHeight*0.55}
                      w={isLarge?deviceWidth*0.48:deviceWidth*0.46}
                    />

                    <div
                      className={`absolute `}
                      style={{
                        top: deviceHeight*0.25,
                        right: deviceWidth*(isLarge?0.17:0.15),
                      }}
                      
                    >
                      <PrairiButton
                        label="View slide deck"
                        size="xl"
                        border="green"
                        rounded="soft"
                        bgColor="clear"
                        click={() =>
                          handleShownDeck(
                            showInterested ? shownStartup : shownArchivedStartup
                          )
                        }
                      />
                    </div>
                  </div>
                }
                {shownArchivedStartup && showArchived && !shownArchivedDeck && (
                  <div className="mb-0 pb-0">
                    <FloatingButtons
                      interested={() =>
                        setInterestedPopUp(shownArchivedStartup.id)
                      }
                      notInterested={() =>
                        setNotInterestedPopUpFromArchived(
                          shownArchivedStartup.id
                        )
                      }
                      archived={() => setArchivedPopUp(shownArchivedStartup.id)}
                    />
                  </div>
                )}
              </div>
            </div>
          )}

          {(showInterested
            ? shownStartup && shownDeck
            : shownArchivedStartup && shownArchivedDeck) && (
            <div className="flex ">
              {
                <div
                  className={` mx-4 overflow-y-auto  ${showArchived && "pb-[120px]"}`}
                  style={{maxHeight: deviceHeight*0.75, width: deviceWidth/4}}
                >
                  <StartupProfile
                    startup={
                      showInterested ? shownStartup : shownArchivedStartup
                    }
                    archived
                    dot={`${showInterested ? "green" : "yellow"}`}
                    onClose={handleCloseProfileAndDeck}
                    onCloseProfile={handleCloseProfileAndDeck}
                    investor={currentUserData}
                    share={() =>
                      handleShare(
                        showInterested
                          ? shownStartup.id
                          : shownArchivedStartup.id
                      )
                    }
                    state={
                      currentUserData.formData.company.startupstates.length <
                      usStates.length
                    }
                    notInterested={() =>
                      setNotInterestedPopUp(
                        showInterested
                          ? shownStartup.id
                          : shownArchivedStartup.id
                      )
                    }
                    videoPitchTracking={() =>
                      handleVideoPitchTracking(
                        showInterested
                          ? shownStartup.id
                          : shownArchivedStartup.id,
                        currentUserId,
                        isMobile,
                        showInterested
                          ? shownStartup.formData.company.videoPitch
                          : shownArchivedStartup.formData.company.videoPitch
                      )
                    }
                    pitchTracking={() =>
                      handlePitchTracking(
                        showInterested
                          ? shownStartup.id
                          : shownArchivedStartup.id,
                        currentUserId,
                        isMobile,
                        showInterested
                          ? shownStartup.formData.company.videoPitch
                          : shownArchivedStartup.formData.company.videoPitch
                      )
                    }
                    // viewer={(showInterested?shownStartup.formData.company.deck.includes("firebasestorage"?"deck":"video"):shownArchivedStartup.formData.company.deck.includes("firebasestorage"?"deck":"video"))}
                    setEmail={handleEmail}
                    setName={handleName}
                    setPhone={handlePhone}
                    shareError={shareError}
                    isMobile={isMobile}
                  />
                </div>
              }
              <div className="pl-4" style={{ marginTop: -(navBarHeight-15) }}>
                <Viewer
                  src={showInterested ? interestedViewerSrc : archivedViewerSrc}
                  h={deviceHeight*0.8}
                  w={deviceWidth*0.70}
                />
              </div>
            </div>
          )}
          {shownArchivedDeck && showArchived && (
            <div className="">
              <FloatingButtons
                interested={() => setInterestedPopUp(shownArchivedStartup.id)}
                notInterested={() =>
                  setNotInterestedPopUpFromArchived(shownArchivedStartup.id)
                }
                archived={() => setArchivedPopUp(shownArchivedStartup.id)}
              />
            </div>
          )}
          <Modal
            open={archivedPopUp === shownArchivedStartup.id}
            border="yellow"
          >
            <div className="flex flex-col p-2 items-center text-center text-sm">
              <div className="flex flex-col justify-center">
                <div className="flex items-center justify-center">
                  <h1 className="text-lg ml-12">
                    <span className="bold">View Later </span>
                    <span className="underline">again</span>?
                  </h1>
                  <PrairiButton
                    label="Yes"
                    size="xs"
                    textColor="whiteText"
                    border="no-border"
                    bgColor="lighter"
                    rounded="soft"
                    click={() => setArchivedPopUp(false)}
                  />
                </div>
                <p className="mb-2">
                  Other investors have clicked 'Interested' on this match.
                </p>
                <p className="mb-4">
                  Matches disappear after they receive their funding.
                </p>
              </div>
              <div className="flex ">
                <PrairiButton
                  label="Interested"
                  border="thinGreen"
                  size="md"
                  rounded="full"
                  textColor="whiteText"
                  click={() =>
                    openInterestedCloseArchived(shownArchivedStartup.id)
                  }
                />
                <PrairiButton
                  label="Not Interested"
                  border="thinRed"
                  size="md"
                  rounded="full"
                  textColor="whiteText"
                  click={() =>
                    openNotInterestedCloseArchived(shownArchivedStartup.id)
                  }
                />
              </div>
            </div>
          </Modal>
        </div>
      );
    }
  }
};

export default Dashboard1;
