import React from "react";
import { CgFacebook } from "react-icons/cg";
import { BsTwitter } from "react-icons/bs";
import { Link, useNavigate, useLocation } from "react-router-dom";

const Footer = () => {
  const location = useLocation();
  const noFooter = /^\/notifications\/[a-zA-Z0-9]+$/i.test(location.pathname)  || location.pathname==="/getfunded" || location.pathname==="/getfunded/mobile" || /^\/startup\/[a-zA-Z0-9]+\/[a-zA-Z0-9]+$/i.test(location.pathname) || /^\/newdeck\/[a-zA-Z0-9]+$/i.test(location.pathname);
  const footerWithMargin = location.pathname==="/dashboard";
  return (
    <div style={{backgroundColor: footerWithMargin?"#303030":"", paddingTop: "10px"}}>
    {!noFooter && 
    <div
      style={{
        //paddingTop: footerWithMargin?"40px":"",
        bottom: "0",
        left: "0",
        right: "0",
        width: "100%",
        backgroundColor: "#262626",
        zIndex: "500",
        paddingLeft: "10%"
      }}
      className="
      py-6 px-4
      flex flex-col
      items-center text-center
      justify-center
      sm:text-lg"
    >
      <div
        className="

        flex flex-col
        md:flex-row
        md:justify-center

        text-[#000]
         font-[500]
    
        
        my-2"
      >
        <Link className="md:mx-2 text-bluePrairi" to="/partner">
          Partner With Us
        </Link>
        <div className="text-customWhite font-bold">·</div>
        <Link className="md:mx-2 text-bluePrairi" to="https://prairi-designs.printify.me/products">
          Merchandise
        </Link>
        <div className="text-customWhite font-bold">·</div>
        <Link className="md:mx-2 text-bluePrairi" to="/partner">
          Contact Us
        </Link>
      </div>
      <div className="flex text-customWhite text-4xl my-3 ">
        <a href="http://www.facebook.com/prairimatch" target="_blank" rel="noopener noreferrer" className="w-[50px]">
          <CgFacebook className="mx-2" />
        </a>
        <a href="https://twitter.com/PRAIRI_com" target="_blank" rel="noopener noreferrer" className="w-[50px]">
        <BsTwitter className="mx-2"/>
        </a>
        
      </div>
      <div className="my-4 md:flex md:justify-center md:items-center text-customWhite text-sm">
        <p className="md:mx-2">
          <span>&#169;</span> Copyright <span>{new Date().getFullYear()}</span>
        
         <span className="ml-1">· All Rights Reserved</span></p>

      </div>
      <p className="md:mx-2 text-customWhite text-sm w-[100%]">PRAIRI and its unicorn are registered trademarks.</p>
    </div>
    }
    </div>
  );
};

export default Footer;
